import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuMain} from './SidebarMenuMain'

const SidebarMenu = () => {
  const intl = useIntl()
  return (
    <>
      <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
        <div
          id='kt_app_sidebar_menu_wrapper'
          className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
          data-kt-scroll='true'
          data-kt-scroll-activate='true'
          data-kt-scroll-height='auto'
          data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
          data-kt-scroll-wrappers='#kt_app_sidebar_menu'
          data-kt-scroll-offset='5px'
          data-kt-scroll-save-state='true'
        >
          <div
            className='menu menu-column menu-rounded menu-sub-indention px-3'
            id='#kt_app_sidebar_menu'
            data-kt-menu='true'
            data-kt-menu-expand='false'
          >
            <SidebarMenuMain />
          </div>
        </div>
      </div>

      <div
        className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6'
        id='kt_app_sidebar_footer'
        style={{textAlign: 'center'}}
      >
        <a
          href='https://changelog.taskrush.com.br/'
          target='_blank'
          className='btn-flex flex-center overflow-hidden text-nowrap px-0 h-40px w-100 text-center menu-title'
          style={{color: '#92929F'}}
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
        >
          <span className='btn-label'>{intl.formatMessage({id: 'GENERAL.VERSION'}, {number: '1.5.52'})}</span>
        </a>
      </div>
    </>
  )
}

export {SidebarMenu}
