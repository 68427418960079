import {FC, useEffect, useRef} from 'react'
import {UserModalContent} from '../../../../_metronic/partials/modals/user-create-modal-stepper/UserModalContent'
import {initialUser} from '../../../../_metronic/partials/modals/user-create-modal-stepper/core/_models'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../../../modules/auth'
import { LimitUsersWidget } from '../../../../_metronic/partials/widgets/_new/engage/LimitUserWidget'

export const Step2: FC = () => {
  const stepperRefUserForm = useRef<HTMLDivElement | null>(null)
  const stepperUserForm = useRef<StepperComponent | null>(null)
  const {currentUser} = useAuth()
  const loadStepper = () => {
    stepperUserForm.current = StepperComponent.createInsance(
      stepperRefUserForm.current as HTMLDivElement
    )
  }

  useEffect(() => {
    loadStepper()
  }, [stepperRefUserForm.current])

  return currentUser?.instance?.isFree || currentUser?.instance?.total_active_users! >= currentUser?.instance?.total_users! ? (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bold text-dark'>Gerenciando usuários</h2>
        <LimitUsersWidget />
      </div>
    </div>
  ) : (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bold text-dark'>Gerenciando usuários</h2>
        <div className='text-gray-700 fw-semibold fs-5'>
          Inicialmente, vamos configurar a jornada de trabalho do seu usuário e alguns dados
          importantes. Lembre-se de que essas primeiras infomações são essenciais para que o
          TaskRush te ajude a gerenciar os projetos e tarefas.{' '}
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-12 bg-body'>
            <UserModalContent
              userId={undefined}
              user={initialUser}
              stepper={stepperUserForm}
              stepperRef={stepperRefUserForm}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
